import { RaffleImages } from "../raffle/images";
import { Box, Chip, Grid, Typography } from "@mui/material";
import RaffleTickets from "../raffle/tickets";
import { IRaffle } from "../../store/interfaces/IRaffle";
import { useIsMobileView } from "../../utils/responsive";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";
import TopPurchasers from "../raffle/topPurchasersPublic";
import { useGetTopPurchasersQuery } from "../../store/api";
import { getPrimaryColorByAccountId } from "../../utils/styleConstants";
import { WhatsApp } from "@mui/icons-material";
import { AwardInformation } from "../raffle/awardInformation";
import TicketsFinder from "../ticketsFinder";

interface ActiveRaffleProps {
  activeRaffle: IRaffle;
}

export const ActiveRaffle = ({ activeRaffle }: ActiveRaffleProps) => {

  const styles = {
    root: {
      backgroundColor: '#FFF',
      color: '#000',
      textAlign: 'center',
      padding: '1rem',
      borderTop: '1px solid #444',
      fontFamily: 'SUSE',
      width: '100%',
      bottom: 0,
      right: 0,
      left: 0,
    },
    chip: {
      color: '#000',
      padding: '1rem',
      cursor: 'pointer',
    },
  };

  const isMobile = useIsMobileView();
  const accountInformation = useAppSelector(({ account }: RootState) => account);

  const { data: topPurchasersFounded } = useGetTopPurchasersQuery(activeRaffle.id, {
    skip: activeRaffle.id === '',
    refetchOnReconnect: true,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const openWhatsAppChat = () => {
    const whatsappUrl = `https://viprifas.com`;
    window.open(whatsappUrl, '_blank');
  }

  return (
    <Grid container>
      <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
      <Grid pb={2}>
          <RaffleImages images={activeRaffle.awards.flatMap(element => element.images)}></RaffleImages>
        </Grid>
        <Grid container  xs={12} xl={12} lg={12} md={12} sm={12} padding={isMobile ? 0 : 1}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
              <Typography variant="h3" textAlign={"center"} fontFamily={"Phrastic"} fontWeight={"bold"} textTransform={"uppercase"}>
                {activeRaffle.name}
              </Typography>
            </Grid>
           
            <Grid mt={5}>
            {activeRaffle.awards.map((award, i) => (
              i < 2 &&
                <Grid item key={`award-${award.name}`} xs={12} xl={12} lg={12} md={12} sm={12}>
                <AwardInformation award={award} />
                </Grid>
              ))}
        </Grid>
        </Grid>

        <Grid mb={2}>
          {topPurchasersFounded 
          && activeRaffle.type === 'raffle' 
          && accountInformation.id !== 'agudojhonny'
          && <TopPurchasers topPurchasers={topPurchasersFounded}></TopPurchasers>}
        </Grid>

          <Grid sx={{ p: 4 }}>
          <Typography variant="h6" fontWeight={"bold"}>Buscador de tickets</Typography>
          <TicketsFinder isPublic={false} raffleId={activeRaffle.id} activeRaffleName={activeRaffle.name} />
        </Grid>

        <Grid sx={{ p: 4 }}>
          <Typography variant="h6" fontWeight={"bold"}>Comprar tickets</Typography>
        </Grid>

        <Grid item  xs={12} xl={12} lg={12} sm={12}>
          <RaffleTickets raffleId={activeRaffle.id} limit={activeRaffle.limit} ticketPriceUSD={activeRaffle.ticketPriceUSD} ticketPrice={activeRaffle.ticketPrice} availablePaymentMethods={activeRaffle.availablePaymentMethods} raffleType={activeRaffle.type} />
        </Grid>
        <Grid item  xs={12} xl={12} lg={12} sm={12} p={4}>
          { accountInformation.id === 'agudojhonny' &&    <Box sx={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Chip
                onClick={openWhatsAppChat}
                sx={{ ...styles.chip }}
                label="Creador de la plataforma"
                icon={<WhatsApp />}
                variant="outlined"
              />
          </Box> }
        </Grid>
      </Grid>
    </Grid>
  );
};

interface Conversion {
    dolarPrice: number;
    dolarPriceCurrency: string;
}

export interface BankAccountItem {
    name?: string;
    href?: string;
    dni?: string;
    bank?: string;
    accountType?: string;
    accountNumber?: string;
    ticketPrice?: string;
    ticketPriceAmount: number;
    ticketCurrency: string;
    conversion?: Conversion;
}

interface BankAccountConfig {
    accountId: string;
    bankAccounts: BankAccountItem[]
}

export const BANK_ACCOUNTS: BankAccountConfig[] = [
    {
        accountId: "agudojhonny",
        bankAccounts: [
            {
                name: "Jose Alfonso",
                bank: "Banco Falabella 🇨🇱",
                accountType: "Cuenta Corriente",
                accountNumber: "15842418179",
                dni: "RUT: 27.146.773-7",
                ticketPrice: "2000 CLP",
                ticketPriceAmount: 2000,
                ticketCurrency: "CLP"
            },
            {
                 dni: "Cédula: V30.096.999",
                 bank: "Banco de Venezuela 🇻🇪",
                 accountType: "Pago movil",
                 accountNumber: "04142522562",
                 ticketPrice: "206.3BS",
                 ticketPriceAmount: 206.3,
                 href: 'https://yourbankwebsite-chile.com',
                 ticketCurrency: "BS",
                 conversion: {
                     dolarPrice: 103.15,
                     dolarPriceCurrency: "BS",
                 }
             },
            // {
            //    bank: "Zelle 🇺🇸",
            //    name: 'Ronald Benitez',
            //    dni: 'Añadir número de confirmación completo.',
            //    accountNumber: "rabv2505@gmail.com",
            //    ticketPrice: "$2 dólar",
            //    ticketPriceAmount: 2,
            //    ticketCurrency: "USD"
            //},
            // {
            //     bank: "Zelle 🇻🇪 🇺🇸",
            //     name: "venezwindows@gmail.com",
            //     accountType: "Adjuntar comprobante completo",
            //     ticketPrice: "$1 dólar",
            //     ticketPriceAmount: 1,
            //     ticketCurrency: "USD"
            // },
        ]
    },
    {
        accountId: "rifas-trebol",
        bankAccounts: [
            {
                name: "Mariaeugenia Carrizo",
                bank: "Banco Falabella 🇨🇱",
                accountType: "Cuenta Corriente",
                accountNumber: "11600037057",
                dni: "RUT: 26.463.848-8",
                ticketPrice: "1000 CLP",
                ticketPriceAmount: 1000,
                ticketCurrency: "CLP"
            },
            {
                name: "Mariaeugenia Carrizo",
                bank: "Banco Estado 🇨🇱",
                accountType: "Cuenta Corriente",
                accountNumber: "29100109911",
                dni: "RUT: 26.463.848-8",
                ticketPrice: "1000 CLP",
                ticketPriceAmount: 1000,
                ticketCurrency: "CLP"
            },
            {
                bank: "Zelle 🇻🇪 🇺🇸",
                name: "Javier Cuello",
                dni: "404 552 20 96",
                accountType: "Adjuntar comprobante completo",
                ticketPrice: "$1 dólar",
                ticketPriceAmount: 1,
                ticketCurrency: "USD"
            },
            {
                name: "Fanny Valencia",
                dni: "Cédula: V12.230.741",
                bank: "Banco Mercantil 🇻🇪",
                accountType: "Cuenta Corriente",
                accountNumber: "01050093110093431988",
                ticketPrice: "80BS",
                ticketPriceAmount: 80,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 80,
                    dolarPriceCurrency: "BS",
                }
            },
        ]
    },
]

import { AddCircle , Dashboard, Doorbell, Home, Paid, EmojiPeople } from '@mui/icons-material';
import { Alert } from '@mui/material';
export const PAGES = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: (
      <Paid fontSize="medium" />
    ),
  },
  {
    name: "Ordenes de compra",
    url: "/purchaseOrders",
    icon: (
      <Paid fontSize="medium" />
    ),
  },
  {
    name: "Trabajo pendiente",
    url: "/pendingWork",
    icon: (
      <Doorbell fontSize="medium" />
    ),
  },
  {
    name: "Compradores",
    url: "/purchasers",
    icon: (
      <EmojiPeople fontSize="medium" />
    ),
  },
  {
    name: "Ganadores",
    url: "/winners",
    icon: (
      <Paid fontSize="medium" />
    ),
  },
  {
    name: "Números",
    url: "/soldTickets",
    icon: (
      <Paid fontSize="medium" />
    ),
  },
  {
    name: "Crear rifa",
    url: "/create",
    icon: (
      <AddCircle fontSize="medium" />
    ),
  },
];
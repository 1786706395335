import { BankAccountItem } from "../../constants/bankAccounts";

interface Award {
  name: string;
  type: string;
  features: string[];
  images: File[];
  order: number;
}


interface AwardDTO {
  name: string;
  type: string;
  features: string[];
  images: string[];
  order: number;
}

interface ICreationRaffle {
  id?: string;
  name: string;
  limit: number;
  ticketPrice: number;
  ticketPriceUSD: number;
  type: string;
  sorterId?: string;
  status?: string;
  awards: Award[];
  accountId?: string;
  availablePaymentMethods: string[];
  hasReferenceNumber: boolean;
  bankAccounts: BankAccountItem[];
}



interface ICreationRaffleImageDTO {
  accountId: string;
  imageName: string;
  imageFile: File;
  raffleId: string;
}

interface ICreationRaffleDTO {
  id?: string;
  name: string;
  limit: number;
  ticketPrice: number;
  ticketPriceUSD: Number;
  type: string;
  sorterId?: string;
  hasReferenceNumber: boolean;
  status?: string;
  accountId?: string;
  awards: AwardDTO[];
  availablePaymentMethods: string[];
  bankAccounts: BankAccountItem[];
}

export const InitialAwards: Award[] = [
  {
    name: "",
    type: "car",
    features: [],
    images: [],
    order: 0,
  },
];

export const InitialCreationRaffle: ICreationRaffle = {
  name: "",
  limit: 1000,
  ticketPrice: 1000,
  ticketPriceUSD: 2,
  type: "",
  awards: InitialAwards,
  hasReferenceNumber: false,
  availablePaymentMethods: [],
  bankAccounts: [],
};

export const InitialCreationRaffleReset: ICreationRaffle = {
  name: "",
  limit: 1000,
  ticketPrice: 1000,
  ticketPriceUSD: 2,
  type: "",
  awards: InitialAwards,
  availablePaymentMethods: [],
  hasReferenceNumber: false,
  bankAccounts: [],
};

export type {
  ICreationRaffle,
  ICreationRaffleDTO,
  ICreationRaffleImageDTO,
  Award,
  AwardDTO,
};
